import { useQueryClient } from '@tanstack/react-query';
import { is } from 'ramda';
import { useEffect } from 'react';

import { usePaymentStatusAtom, useROFormMessageAtom } from '$hooks/state';
import { ORDER_REQUEST_QUERY_KEY } from '$src/constants';

export const use3DSListenner = () => {
  const { onPaymentStatusChange, paymentStatus } = usePaymentStatusAtom();
  const formMessage = useROFormMessageAtom();
  const client = useQueryClient();

  useEffect(() => {
    const messageHandler = (event: MessageEvent<any>) => {
      const { data } = event;

      if (is(String, data)) {
        const match = data.match(/payment_intent_client_secret=([^&]+)/);

        if (match) {
          onPaymentStatusChange({ display: false, url: undefined, cartId: paymentStatus.cartId });

          if (paymentStatus?.cartId) {
            client.refetchQueries({ queryKey: [ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId] });
          }
        }
      }
    };

    window.addEventListener('message', messageHandler, true);

    return () => window.removeEventListener('message', messageHandler);
  }, [client, formMessage, onPaymentStatusChange, paymentStatus]);
};
