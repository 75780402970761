function i(e, t) {
  return 0 .toLocaleString(e, {
    style: "currency",
    currency: t,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).replace(/\d/g, "").trim();
}
function u(e) {
  return typeof e == "string" ? parseFloat(e) === 0 : !1;
}
function c(e, t) {
  const { currency: r = "EUR" } = t, o = t.locale ?? (r === "EUR" ? "fr-FR" : "en-US");
  return new Intl.NumberFormat(o, {
    currency: r,
    style: "currency",
    maximumFractionDigits: 2
  }).format(e);
}
function a(e) {
  return e.toString().replace(".", ",");
}
const l = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  formatDecimal: a,
  formatPrice: c,
  getCurrencySymbol: i,
  isFree: u
}, Symbol.toStringTag, { value: "Module" }));
function n(e) {
  try {
    const t = window[e], r = "__storage_test__";
    return t.setItem(r, r), t.removeItem(r), !0;
  } catch {
    return !1;
  }
}
function s(e, t, r) {
  n(e) && window[e].setItem(t, JSON.stringify(r));
}
function m(e, t) {
  if (!n(e))
    return null;
  const r = window[e].getItem(t);
  return r ? JSON.parse(r) : null;
}
function f(e, t) {
  n(e) && window[e].removeItem(t);
}
const g = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearStorage: f,
  getStorage: m,
  setStorage: s,
  storageAvailable: n
}, Symbol.toStringTag, { value: "Module" })), d = (e, t) => {
  let r = null;
  return (...o) => {
    window.clearTimeout(r), r = window.setTimeout(() => {
      e.apply(null, o);
    }, t);
  };
}, _ = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  debounce: d
}, Symbol.toStringTag, { value: "Module" })), S = {
  ...g,
  ..._,
  ...l
};
export {
  f as clearStorage,
  d as debounce,
  S as default,
  a as formatDecimal,
  c as formatPrice,
  i as getCurrencySymbol,
  m as getStorage,
  u as isFree,
  s as setStorage,
  n as storageAvailable
};
