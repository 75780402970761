import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway, hasXCountryGatewayHeader } from '$src/http';

export function useCart() {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [CART_QUERY_KEY],
    queryFn: async () => {
      const response = await gateway.get<Cart>(`${baseUrl}`);
      if (response?.data?.shipping?.ready === false) {
        queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY] });
      }
      return response.data;
    },
    enabled: Boolean(baseUrl) && Boolean(hasXCountryGatewayHeader()),
  });
}
