import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Address, Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCountriesFromCache } from '$src/hooks/queries';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { gateway } from '$src/http';

export const UPDATE_SHIPPING_ADDRESS_MUTATION_KEY = 'MUTATION/UPDATE_SHIPPING_ADDRESS';

export const useUpdateShippingAddress = () => {
  const { initCorrections } = useCorrectionsAtom();
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const { track } = useTrackAmplitude();
  const countries = useCountriesFromCache();

  const mutation = useMutation({
    mutationFn: ({ address, email }: { address: Address; email: string }) => {
      const countryCodeContext =
        (address?.countryCode && address?.countryCode.length !== 0 ? address?.countryCode : undefined) ??
        countries.find((country) => country.country === address?.country)?.value;
      return gateway.post<Cart>(`${baseUrl}/update-shipping-address`, { address, email, countryCodeContext });
    },
    mutationKey: [UPDATE_SHIPPING_ADDRESS_MUTATION_KEY],
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
      if (data.corrections?.length) {
        initCorrections(data.corrections);
      }
      track('Shipping methods loaded');
    },
  });

  return { updateShippingAddress: mutation.mutateAsync, state: mutation.status, reset: mutation.reset };
};
