import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { AddDiscountInput } from '$api/[shopId]/[cartId]/add-discount';
import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { gateway } from '$src/http';

const MUTATION_CART_ADD_DISCOUNT_KEY = 'MUTATION/ADD_DISCOUNT';

export const useAddDiscount = () => {
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const { track } = useTrackAmplitude();

  const mutation = useMutation({
    mutationFn: (discount: AddDiscountInput) => gateway.post<Cart>(`${baseUrl}/add-discount`, discount),
    mutationKey: [MUTATION_CART_ADD_DISCOUNT_KEY],
    onSuccess: ({ data }, variables) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
      track('Discount code succeeded', { code: variables.code });
    },
    onError: (_, variables) => {
      track('Discount code failed', { code: variables.code });
    },
  });

  return {
    addDiscount: mutation.mutateAsync,
    status: mutation.status,
    reset: mutation.reset,
  };
};
