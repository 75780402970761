import { Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import posthog from 'posthog-js';
import { useCallback, useEffect } from 'react';

import { useShop, useUserInfo } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';

export interface KeyValue {
  [key: string]: string | number | undefined | string[] | number[];
}

export const addIdentify = (name: string, value: string) => {
  const identifyEvent = new Identify();
  identifyEvent.set(name, value);
  identify(identifyEvent);
};

export const useTrackAmplitude = () => {
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { data: currentUser } = useUserInfo();

  useEffect(() => {
    if (!shop?.features.includes('analytics_amplitude') || !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      return;
    }
    if (currentUser?.id) {
      setUserId(currentUser?.id);
    }
  }, [currentUser?.id, shop?.features]);

  const trackEvent = useCallback(
    (name: string, props?: KeyValue) => {
      if (!shop?.features.includes('analytics_amplitude') || !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
        return;
      }
      try {
        return track(name, {
          ...props,
          shop: shop?.name,
          language: message?.language,
          shopId: message?.shopId,
          cartId: message?.cart.id,
          platform: shop?.platformType,
          source:
            message?.source === 'product-form'
              ? 'product'
              : message?.source === 'customV2'
                ? 'custom'
                : message?.source,
          posthogSessionId: posthog?.sessionManager?.checkAndGetSessionAndWindowId(),
        });
      } catch (error) {
        console.error('Failed to track with Amplitude', { error });
        return;
      }
    },
    [
      message?.cart.id,
      message?.language,
      message?.shopId,
      message?.source,
      shop?.features,
      shop?.name,
      shop?.platformType,
    ],
  );

  return {
    track: trackEvent,
  };
};
