import { useMemo } from 'react';

/**
 * NOTE: Minified version of _all-countries to reduce loading time. It only has attributes needed by useCountries(). Generate it using this snippet:
 *
 * const fs = require('fs');
 * const data = fs.readFileSync('_all-countries.json', 'utf8');
 * const countries = JSON.parse(data).map((e) => ({label: `${e.flag} ${e.name.common}`,value: e.cca2,country: e.name.common,flag: e.flag,regex: e?.postalCode?.regex})).sort((prev, next) => prev.label.localeCompare(next.label))
 * fs.writeFileSync('_minified-countries.json', JSON.stringify(countries));
 * 
 * or here : https://jsoneditoronline.org/
 * function query (data) {
  return _.chain(data)
    .map(item => ({value: item?.cca2, translations: { fr: item.translations.fra, de: item.translations.deu, it: item.translations.ita, es: item.translations.spa, en: item.name, }, flag: item.flag, postalCode: item.postalCode }))
    .value()
}
 */
// import countries from '$src/hooks/queries/useCountries/_all-countries.json';

import countries from '$src/hooks/queries/useCountries/_minified-countries.json';
import { useUserLocationDetection } from '$src/hooks/queries/useUserLocationDetection';

export function useCountriesFromCache() {
  const { data: location } = useUserLocationDetection();
  const locationDefault = location?.location?.country.code.toLowerCase() ?? 'fr';
  const data = countries.map((country) => {
    const translatedCountry =
      locationDefault in country.translations
        ? //@ts-expect-error - clean
          country.translations[locationDefault].common
        : country.translations['fr'].common;
    return {
      ...country,
      label: `${country.flag} ${translatedCountry}`,
      country: translatedCountry,
    };
  });

  return useMemo(() => data, [data]);
}
