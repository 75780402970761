import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import type { CheckoutV1 } from '@getjust/api-schema';

import { useCart, useShop } from '$hooks/queries';
import { useFormMessageAtom, useROFormMessageAtom } from '$hooks/state/useFormMessageAtom';
import { usePaymentStatusAtom } from '$hooks/state/usePaymentStatusAtom';
import { MAX_FAILURE_COUNT, ORDER_REQUEST_QUERY_KEY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCleanupCart } from '$src/hooks/mutations/useCleanupCart';
import { useErrorAtom } from '$src/hooks/state/useErrorAtom';
import { justAPI } from '$src/http';
import { capturePosthogAnalytics } from '$src/utils';

export const useOrderStatus = () => {
  const { formMessage, onFormMessageChange } = useFormMessageAtom();
  const { paymentStatus } = usePaymentStatusAtom();
  const { t, i18n } = useTranslation('common');
  const toast = useToast();
  const { data: cart } = useCart();
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { authState } = useAuth() ?? {};
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();
  const { mutateAsync: cleanUpCart } = useCleanupCart();
  const { onErrorChange } = useErrorAtom();
  return useQuery({
    queryKey: [ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId],
    queryFn: async () => {
      try {
        const { data } = await justAPI.get<CheckoutV1.OrdersGetStatusResponseBody>(
          `/v1/status/${paymentStatus?.cartId}`,
        );
        if (data?.outcome === 'ready') {
          track('Order created', {
            totalAmount: cart?.totalAmount,
            currency: cart?.currency,
            product: cart?.lineItems?.length,
            deliveryCountry: cart?.shipping.address?.country,
          });
          try {
            capturePosthogAnalytics('CREATE_ORDER_SUCCESS', {
              seller: shop?.name,
              source: message!.source,
              totalAmount: cart?.totalAmount,
            });
            await cleanUpCart();
          } catch (error) {
            console.error('Failed to clean up cart', { error });
          }

          postMessage({
            commandStatus: 'CREATED',
            orderUrl: data?.url,
            sessionKey: formMessage?.sessionKey ?? 'DEFAULT_KEY',
            emitter: 'JUST_FORM',
            action: 'NAVIGATE',
            fbq: {
              currency: cart?.currency ?? 'EUR',
              value: cart?.totalAmount ?? 0.0,
            },
            analytics: {
              address: {
                province: data?.analytics?.address?.province,
                provinceCode: data?.analytics?.address?.provinceCode,
                city: data?.analytics?.address?.city,
                country: data?.analytics?.address?.country,
                countryCode: data?.analytics?.address?.countryCode,
                zip: data?.analytics?.address?.zip,
              },
              cartId: cart?.id,
              orderId: data?.analytics?.orderId,
              name: data?.analytics?.name,
              totalAmount: data?.analytics?.totalAmount,
              shippingAmount: data?.analytics?.shippingAmount,
              taxAmount: data?.analytics?.taxAmount,
              totalDiscount: data?.analytics?.discountAmount,
              currency: data?.analytics?.currency,
              products: data?.analytics?.products,
            },
          });
        }
        return data;
      } catch (error: any) {
        onFormMessageChange({
          commandStatus: 'ERROR',
        });
        if (
          error?.response?.data?.outcome === 'notReady' &&
          error?.response?.data?.reason === 'paymentError' &&
          error?.response?.data?.error
        ) {
          track('Order creation failed', { error: JSON.stringify(error.response.data.error) });
          const titleError = `errors.stripe.${error?.response?.data?.error}.title`;
          const sentenceError = `errors.stripe.${error?.response?.data?.error}.sentence`;
          const genericTitleError = `errors.stripe.generic_decline.title`;
          const genericSentenceError = `errors.stripe.generic_decline.sentence`;
          onErrorChange({
            title: i18n.exists(titleError) ? t(titleError) : t(genericTitleError),
            sentence: i18n.exists(sentenceError) ? t(sentenceError) : t(genericSentenceError),
          });
          if (error?.response?.data?.error === 'payment_intent_authentication_failure') {
            track('3DS check failed');
          }
        }
        throw error;
      }
    },
    enabled: Boolean(paymentStatus?.cartId) && authState === 'authenticated' && !paymentStatus?.display,
    retry: (_failCount: number, error: any) => {
      if (_failCount < MAX_FAILURE_COUNT && error?.response?.data?.reason === 'orderNotFound') {
        if (_failCount === 3) {
          toast({
            description: 'warningProcessing',
            status: 'info',
            position: 'top',
            duration: 5000,
            isClosable: true,
          });
        }
        return true;
      } else if (error?.response?.data?.outcome === 'notReady') {
        return false;
      } else {
        toast({
          description: 'failedOrder',
          status: 'warning',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        return false;
      }
    },
  });
};
