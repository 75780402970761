import { useRouter as useNextRouter } from 'next/router';
import { useMemo } from 'react';

import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

import { useROFormMessageAtom } from '$hooks/state';

type Url = string | UrlObject;

interface IntarnalUseRouterReturnType extends Omit<NextRouter, 'push'> {
  push: (url: Url, as?: Url) => Promise<boolean>;
}

export const useRouter = (): IntarnalUseRouterReturnType => {
  const router = useNextRouter();
  const message = useROFormMessageAtom();

  return useMemo(
    () => ({
      ...router,
      push: (path: Url, as?: Url) => router.replace(path, as, { shallow: true, locale: message?.language }),
    }),
    [router, message],
  );
};
