import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { useCartIdAtom, useCountryCodeAtom, useFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY } from '$src/constants';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useCountryCodeContextFromLocalStorage } from '$src/hooks/state/useCountryCodeContextFromLocalStorage';
import { gateway } from '$src/http';
import { PrepareCartInput } from '$src/pages/api/[shopId]/prepare-cart';

export const MUTATION_PREPARE_CART_KEY = 'MUTATION/PREPARE_CART';

export const usePrepareCart = () => {
  const { onCartIdChange } = useCartIdAtom();
  const { initCorrections } = useCorrectionsAtom();
  const { formMessage } = useFormMessageAtom();
  const queryClient = useQueryClient();
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();
  const { country } = useCountryCodeAtom();
  const countryCodeContext = useCountryCodeContextFromLocalStorage() ?? country;

  return useMutation({
    mutationFn: (cartInput: PrepareCartInput) =>
      gateway.post<Cart>(`/api/${formMessage?.shopId}/prepare-cart`, {
        cart: cartInput.cart,
        countryCodeContext: countryCodeContext,
      }),
    mutationKey: [MUTATION_PREPARE_CART_KEY],
    onSuccess: ({ data }) => {
      onCartIdChange(data!.id);
      if (data?.corrections?.length) initCorrections(data?.corrections);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
      postMessage({
        emitter: 'JUST_FORM',
        action: 'SET_SESSION_ID',
        cartId: data.id,
      });
      /* we do it here, as global MutationCache onSuccess callback is executed before this one. So, when it's executed,
         cartId state atom is not already set */
      if (data?.shipping?.ready === false) {
        queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY] });
      }
      track('Checkout form loaded', {
        totalAmount: data.totalAmount,
        currency: data.currency,
        product: data.lineItems?.length,
      });
    },
    onError: (err, payload) => {
      track('Checkout form load failed', {
        payload: JSON.stringify(payload),
        discount: payload?.cart?.discountCodes?.join(' '),
        variantsIds: payload?.cart?.lineItems?.map((item) => item.variantId).join(', '),
      });
    },
  });
};
