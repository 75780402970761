import { useMutation } from '@tanstack/react-query';

import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';

export const MUTATION_CLEAN_UP_CART = 'MUTATION/MUTATION_CLEAN_UP_CART';

export const useCleanupCart = () => {
  const baseUrl = useBaseApiUrl();

  return useMutation({
    mutationFn: () => gateway.post<void>(`${baseUrl}/clean-up`, {}),
    mutationKey: [MUTATION_CLEAN_UP_CART],
  });
};
