import { Badge, Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

type IconWithBadgeProps = {
  imageUrl: string;
  badgeText: string | number;
  alt: string;
};

const IconWithBadge = ({ imageUrl, badgeText, alt }: IconWithBadgeProps) => {
  return (
    <Box
      position="relative"
      display="inline-block"
      width="2.25rem"
      height="2.25rem"
      p={2}
      borderRadius="full"
      border="1px solid rgba(0, 0, 0, 0.02)"
    >
      <Flex alignItems="center" justifyContent="center" width="100%" height="100%" boxSize="1.5rem">
        <Image src={imageUrl} mb="1.5" mr="1.5" alt={alt} boxSize="1.5rem" flexShrink={0} />
      </Flex>
      <Badge
        position="absolute"
        top="-0.38rem"
        right="-0.38rem"
        width="1.3rem"
        height="1.3rem"
        borderRadius="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="2px solid #FFF"
        zIndex={1000}
        bgGradient="linear-gradient(90deg, #8DE690 2.08%, #A5F0A3 18.23%, #A6ECD7 33.33%, #97DADA 51.56%, #B3DAE7 67.71%, #B6C9EC 83.33%, #879ACB 100%)"
      >
        <Text
          color="#050505"
          textAlign="center"
          fontFamily="IBM Plex Mono"
          fontSize="0.6875rem"
          fontStyle="normal"
          fontWeight="bold"
          lineHeight="3"
        >
          {badgeText}
        </Text>
      </Badge>
    </Box>
  );
};

export default IconWithBadge;
