import { Box, Container, Divider, Flex } from '@chakra-ui/react';
import { JustSkeleton } from '@getjust/leaf';
import { useEffect, useRef } from 'react';

import CartDetail from '$components/CartDetail';
import { CartSummary } from '$components/CartSummary';
import { Header } from '$components/Header';
import { LoadingWrapper } from '$components/LoadingWrapper';
import { REFS_MAP } from '$src/constants';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useCart } from '$src/hooks/queries';
import { useLoadingAtom, usePaidLoadingAtom } from '$src/hooks/state/useLoadingAtom';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { isLargerThan750, isLargerThan900, isMobile } = useIsDesktop();
  const portalRef = useRef<HTMLDivElement>();
  const { loading } = useLoadingAtom();
  const { paidLoading } = usePaidLoadingAtom();
  const { data: cart, status: useCartStatus } = useCart();

  useEffect(() => {
    if (portalRef.current) {
      REFS_MAP.set('portal', portalRef);
    }
  }, []);

  useEffect(() => {
    // slide animation for mobiles
    if (!portalRef.current) return;
    const myTimeout = setTimeout(() => {
      portalRef.current?.classList.add('slideIn');
    }, 200);
    return () => clearTimeout(myTimeout);
  }, []);

  return (
    <Box id="just-form-layout" backgroundColor="white" ref={portalRef as any}>
      <LoadingWrapper isLoading={loading} isPaidLoading={paidLoading}>
        <Header />
        <Flex h="91%">
          <Divider
            orientation="vertical"
            hidden={!isLargerThan900}
            position="absolute"
            right="49%"
            border="0.8px solid #E0E0E0"
            borderStyle="dashed"
            h="85%"
            top="5.5rem"
          />
          <Container
            pt={2}
            pb={isLargerThan900 ? 12 : isLargerThan750 ? 8 : 2}
            pl={isLargerThan900 ? 10 : isLargerThan750 ? 8 : 2}
            pr={isLargerThan900 ? 10 : isLargerThan750 ? 8 : 2}
            maxW={isLargerThan900 ? '460px' : isLargerThan750 ? '344px' : 'full'}
            m="0"
            as="main"
            flex="1 1 auto"
            overflow="auto"
            height="100%"
            display="flex"
            flexDirection="column"
            overscrollBehavior="none"
            w={isLargerThan900 ? '380px' : isLargerThan750 ? '300px' : 'full'}
          >
            <CartSummary />
            {children}
          </Container>
          {!isMobile &&
            (useCartStatus !== 'success' && !cart ? (
              <JustSkeleton
                width="25rem"
                height="22.5rem"
                px="12px"
                pb="12px"
                pt="6px"
                ml={isLargerThan900 ? '21px' : 0}
                mt="3.5"
                mb="26px"
                display="flex"
              />
            ) : (
              <Box
                backgroundColor="#F7F7F7"
                borderRadius="20px"
                px="12px"
                pb="12px"
                pt="6px"
                ml={isLargerThan900 ? '21px' : 0}
                mt="3.5"
                w="401px"
                mb="26px"
                display="flex"
                h="80%"
              >
                <CartDetail isUpsellDisabled={true} />
              </Box>
            ))}
        </Flex>
      </LoadingWrapper>
    </Box>
  );
};
