import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { gateway } from '$src/http';
import { UpdateShippingMethodInput } from '$src/pages/api/[shopId]/[cartId]/update-shipping-method';

export const UPDATE_SHIPPING_METHOD_MUTATION_KEY = 'MUTATION/UPDATE_SHIPPING_METHOD';

export const useUpdateShippingMethod = () => {
  const { initCorrections } = useCorrectionsAtom();
  const baseUrl = useBaseApiUrl();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: UpdateShippingMethodInput) =>
      gateway.post<Cart>(`${baseUrl}/update-shipping-method`, input),
    mutationKey: [UPDATE_SHIPPING_METHOD_MUTATION_KEY],
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [CART_QUERY_KEY] });
      const previousCart = queryClient.getQueryData<Cart | undefined>([CART_QUERY_KEY]);
      queryClient.setQueryData<Cart | undefined>([CART_QUERY_KEY], (oldCart): Cart | undefined => {
        if (!oldCart) return oldCart;
        const selected = oldCart.shipping.methods?.find((method) => method.id === variables.shippingMethodId);
        if (!selected) return oldCart;
        return {
          ...oldCart,
          shipping: {
            ...oldCart.shipping,
            selected: [selected],
          },
        };
      });

      return {
        previousCart,
      };
    },
    onError: (_error, _shippinMethod, context) =>
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], context?.previousCart),
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
      if (data.corrections?.length) {
        initCorrections(data.corrections);
      }
    },
  });
};
